<template>
  <div class="cred-img-container">
    <div class="buttons">
      <el-button type="info" icon="el-icon-refresh-left" @click="$router.go(-1)"
        >返回</el-button
      >
      <div style="margin: 10px 0"></div>
      <el-button
        :loading="btnLoading"
        type="success"
        icon="el-icon-takeaway-box"
        @click="handleDown"
        >下载</el-button
      >
    </div>
    <div class="img-wrapper" id="wrapper" v-if="type <= 2">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template v-if="type === 0">
        <img :src="photoPath" class="photo0" />
        <div class="info0">
          <span class="item01">{{ detail.name }}</span>
          <span class="item02">{{ detail.sex === 1 ? "男" : "女" }}</span>
          <span class="item05">{{ detail.deptName }}</span>
          <span class="item06">{{ detail.idCard }}</span>
          <span class="item03">{{
            detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item04">{{ detail.code }}</span>
          <!-- <span class="item05">{{ detail.startTime }} ~ {{ detail.endTime }}</span> -->
        </div>
        <!-- <div class="name">{{ detail.name }}</div> -->
        <div class="time">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <span>{{ time.date }}</span>
        </div>
      </template>
      <template v-else-if="type === 1">
        <img :src="photoPath" class="photo" />
        <div class="info">
          <span class="item item1">{{ detail.name }}</span>
          <span class="item item2">{{ detail.sex === 1 ? "男" : "女" }}</span>
          <span class="item item3">{{
            detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item item4">{{ detail.code }}</span>
          <span class="item item5" v-if="detail.startTime"
            >{{ detail.startTime }} ~ {{ detail.endTime }}</span
          >
        </div>
        <div class="name">{{ detail.name }}</div>
        <div class="time">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <span>{{ time.date }}</span>
        </div>
      </template>
      <template v-else>
        <div class="name2">{{ detail.name }}</div>
        <div class="info2">
          <span>{{ detail.company }}</span>
          <span class="item2">{{ detail.htNumber }}</span>
          <span class="item3"
            >{{ detail.htStartTime }} ~ {{ detail.htEndTime }}</span
          >
        </div>
        <div class="time2">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
        </div>
      </template>
    </div>

    <div class="img-wrapper" id="wrapper" v-else-if="type === 8">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template>
        <img :src="photoPath" class="photo8" />
        <div class="info">
          <span class="item item6">{{ detail.name }}</span>
          <span class="item item7">{{ detail.sex === 1 ? "男" : "女" }}</span>
          <span class="item item8">{{
            detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item item9">{{ detail.code }}</span>
          <span class="item item10" v-if="detail.startTime"
            >{{ detail.startTime }} ~ {{ detail.endTime }}</span
          >
          <div class="item item11">{{ detail.name }}</div>
        </div>

        <div class="time8">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <span>{{ time.date }}</span>
        </div>
      </template>
    </div>

    <div class="img-wrapper" id="wrapper" v-else-if="type === 9">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template>
        <img :src="photoPath" class="photo9" />
        <div class="info">
          <span class="item item12">{{ detail.name }}</span>
          <span class="item item13">{{ detail.sex === 1 ? "男" : "女" }}</span>
          <span class="item item14">{{
            detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item item15">{{ detail.code }}</span>
          <span class="item item16" v-if="detail.startTime"
            >{{ detail.startTime }} ~ {{ detail.endTime }}</span
          >
          <div class="item item17">{{ detail.name }}</div>
        </div>

        <div class="time9">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <span>{{ time.date }}</span>
        </div>
      </template>
    </div>

    <!-- 华蒙星幼儿篮球教练员C证 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 10">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template>
        <img :src="photoPath" class="photo10" />
        <div class="info">
          <span v-if="detail.name.length <= 8" class="item item18">{{
            detail.name
          }}</span>
          <span v-else-if="detail.name.length > 8" class="item item23">{{
            detail.name
          }}</span>
          <span class="item item19">{{ detail.sex === 1 ? "男" : "女" }}</span>
          <!-- 
            此处因为临时情况导致实体证书比系统证书先发行，由于系统证书日期以审批日期为准，因此对这两天导入的C证证书做了日期处理，下方同理
            我尝试过直接通过'navicat'访问数据库修改，但是失败了，所以通过这种方式实现同样的效果
            
            后续可以有的优化方案：
            1. 解决root账户无法直接访问数据的问题，对2023-03-30/31这两天上传的该证书的内容进行修改，一共28本，这是最直接的解决方法
            2. 就这样，保持前端解决日期显示问题的方案，用户的查询和下载，都会得到正确的证书
           -->
          <span class="item item20">{{
            detail.createTime.substring(0, 10) == "2023-03-30" ||
            detail.createTime.substring(0, 10) == "2023-03-31"
              ? "2023-03-24"
              : detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item item21">{{ detail.code }}</span>
          <div v-if="detail.name.length <= 4" class="item item22">
            {{ detail.name }}
          </div>
          <div v-if="detail.name.length > 4" class="item item24">
            {{ detail.name }}
          </div>
        </div>

        <div class="time8">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <!-- 此处也做了2023-03-30和2023-03-31这两天的校验 -->
          <span>{{
            time.year == "2023" &&
            time.month == "03" &&
            (time.date == "30" || time.date == "31")
              ? "24"
              : time.date
          }}</span>
        </div>
      </template>
    </div>

    <!-- 华蒙星幼儿篮球教练员C2-7证 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 11">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template>
        <img :src="photoPath" class="photo11" />
        <div class="info">
          <span v-if="detail.name.length <= 8" class="item item25">{{
            detail.name
          }}</span>
          <span v-else-if="detail.name.length > 8" class="item item23">{{
            detail.name
          }}</span>
          <span class="item item26">{{ detail.sex === 1 ? "男" : "女" }}</span>
        
          <span class="item item27">{{
           detail.createTime && detail.createTime.substring(0, 10)
          }}</span>
          <span class="item item28">{{ detail.code }}</span>
          <div v-if="detail.name.length <= 4" class="item item22">
            {{ detail.name }}
          </div>
          <div v-if="detail.name.length > 4" class="item item24">
            {{ detail.name }}
          </div>
        </div>

        <div class="time8">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <!-- 此处也做了2023-03-30和2023-03-31这两天的校验 -->
          <span>{{
            time.year == "2023" &&
            time.month == "03" &&
            (time.date == "30" || time.date == "31")
              ? "24"
              : time.date
          }}</span>
        </div>
      </template>
    </div>

    <!-- 华蒙星幼儿体育研修班结业证书 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 12">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <template>
        <img :src="photoPath" class="photo11" />
        <div class="info">
          <span v-if="detail.name.length <= 8" class="item item25">{{
            detail.name
          }}</span>
          <span v-else-if="detail.name.length > 8" class="item item23">{{
            detail.name
          }}</span>
          <span class="item item26">{{ detail.sex === 1 ? "男" : "女" }}</span>
        
          <span class="item item27">{{
           detail.idCard
          }}</span>
          <span class="item item28">{{ detail.code }}</span>
          <div v-if="detail.name.length <= 4" class="item item22">
            {{ detail.name }}
          </div>
          <div v-if="detail.name.length > 4" class="item item24">
            {{ detail.name }}
          </div>
        </div>

        <div class="time8">
          <span>{{ time.year }}</span>
          <span>{{ time.month }}</span>
          <!-- 此处也做了2023-03-30和2023-03-31这两天的校验 -->
          <span>{{
            time.year == "2023" &&
            time.month == "03" &&
            (time.date == "30" || time.date == "31")
              ? "24"
              : time.date
          }}</span>
        </div>
      </template>
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 3">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo" />
      <div class="name">{{ detail.name }}</div>
      <div class="time">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div>
      <div class="qishu">{{ detail.periodical }}</div>
      <div class="time3">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 6">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo6" />
      <div class="name6">{{ detail.name }}</div>
      <div class="code6">{{ detail.code }}</div>
      <!-- <div class="time">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div>
      <div class="qishu">{{ detail.periodical }}</div> -->
      <div class="time6">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 4">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo4" />
      <div class="party4">{{ detail.deptName }}</div>
      <div class="name4">{{ detail.name }}</div>
      <div class="code4">{{ detail.code }}</div>
      <!-- <div class="time">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div> -->
    </div>

    <div class="img-wrapper" id="wrapper" v-else-if="type === 5">
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
    </div>

    <!-- 星绳童教师资格证 -->
    <div class="img-wrapper" id="wrapper" v-else>
      <img
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo6" />
      <div class="name6">{{ detail.name }}</div>
      <div class="sex6">{{ detail.sex === 1 ? "男" : "女" }}</div>
      <div class="deptdiv">
        <div
          :class="{
            deptname6: detail.deptName.length <= 11,
            deptname7: detail.deptName.length > 11,
          }"
        >
          {{ detail.deptName }}
        </div>
      </div>
      <div class="code6">{{ detail.code }}</div>
      <div class="validtime">
        <div class="starttime">
          {{ startYear }}.{{ startMonth }}.{{ startDate }}
        </div>
        <span class="wave">~</span>
        <div class="endtime">{{ endYear }}.{{ endMonth }}.{{ endDate }}</div>
      </div>

      <div class="name7">{{ detail.name }}</div>
      <!-- <div class="qishu">{{ detail.periodical }}</div> -->
      <div class="time6">
        <span class="span1">{{ time.year }}</span>
        <span class="span2">{{ time.month }}</span>
        <span class="span3">{{ time.date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      type: 2,
      cover: null,
      detail: {},
      photoPath: "",
      time: {},
      btnLoading: false,
    };
  },

  created() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      this.detail = JSON.parse(this.$route.query.info);
      if (this.detail.categoryName.includes("幼儿篮球裁判员")) {
        this.type = 0;
      }
      if (
        this.detail.categoryName.includes("AC认证") ||
        this.detail.categoryName.includes("WEAC-B") ||
        this.detail.categoryName.includes("公益培训")
      ) {
        this.type = 8;
      } else if (this.detail.categoryName.includes("WEAC-S")) {
        this.type = 9;
      } else if (
        this.detail.categoryName.includes("认证培训") ||
        this.detail.categoryName.includes("WEAC")
      ) {
        this.type = 1;
      } else if (
        this.detail.categoryName.includes("初级") ||
        this.detail.categoryName.includes("一星")
      ) {
        this.type = 3;
      } else if (this.detail.categoryName === "幼儿篮球教师资格证书") {
        this.type = 4;
      } else if (this.detail.categoryName.includes("小小CBA")) {
        this.type = 5;
      } else if (this.detail.categoryName.includes("跳绳")) {
        this.type = 6;
      } else if (this.detail.categoryName.includes("星绳童")) {
        this.type = 7;
      } else if (this.detail.categoryName.includes("C证")) {
        this.type = 10;
      } else if (this.detail.categoryName.includes("教练员证书")) {
        this.type = 11;
      }else if (this.detail.categoryName.includes("幼儿体育研修班")) {
        this.type = 12;
      }
      this.time = {
        year: this.detail.createTime.substring(0, 4),
        month: this.detail.createTime.substring(5, 7),
        date: this.detail.createTime.substring(8, 10),
      };
      if (this.type === 7) {
        this.startYear = this.detail.createTime.substring(0, 4);
        this.startMonth = this.detail.createTime.substring(5, 7);
        this.startDate = this.detail.createTime.substring(8, 10);
        this.endYear = this.detail.endTime.substring(0, 4);
        this.endMonth = this.detail.endTime.substring(5, 7);
        this.endDate = this.detail.endTime.substring(8, 10);
      }
      if (this.detail.icon) {
        let photoPath =
          process.env.VUE_APP_baseApi +
          "/upload/loadImgDataByFileName?fileName=" +
          this.detail.icon;
        this.imgToBase64(photoPath);
      }
      // this.api.getCredDetail({ id: this.$route.query.id }).then(res => {
      //   if (res.success) {
      //     if (res.data.categoryName === '星伙伴教练员') {
      //       this.cover = require('../../assets/img/星伙伴教练员.jpg')
      //     } else if (res.data.categoryName === '俱乐部星教练') {
      //       this.cover = require('../../assets/img/俱乐部星教练.jpg')
      //     } else if (res.data.categoryName === '星宝贝督导师') {
      //       this.cover = require('../../assets/img/星宝贝督导师.jpg')
      //     } else if (res.data.categoryName === '星伙伴(代理)') {
      //       this.cover = require('../../assets/img/星伙伴.jpg')
      //       this.type = 2
      //     } else if (res.data.categoryName === '初级教练') {
      //       this.cover = require('../../assets/img/初级教练.jpg')
      //       this.type = 3
      //     } else if (res.data.categoryName === '初级校长') {
      //       this.cover = require('../../assets/img/初级校长.jpg')
      //       this.type = 3
      //     } else if (res.data.categoryName === '初级课程顾问') {
      //       this.cover = require('../../assets/img/初级课程顾问.jpg')
      //       this.type = 3
      //     } else {
      //       this.cover = process.env.VUE_APP_baseApi + '/upload/loadImgDataByFileName?fileName=' + res.data.path
      //       this.type = 4
      //     }
      //     this.detail = res.data
      //     this.time = {
      //       year: res.data.createTime.substring(0, 4),
      //       month: res.data.createTime.substring(5, 7),
      //       date: res.data.createTime.substring(8, 10)
      //     }
      //     let photoPath = process.env.VUE_APP_baseApi + '/upload/loadImgDataByFileName?fileName=' + res.data.icon
      //     this.imgToBase64(photoPath)
      //   }
      // })
    },

    // 将在线图片转为base64
    imgToBase64(photoPath) {
      let that = this;
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.src = photoPath;
      // image.src = src + '?v=' + Math.random() // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        that.photoPath = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
      };
    },

    // html导出图片
    handleDown() {
      this.btnLoading = true;
      let canvasID = document.getElementById("wrapper");
      let a = document.createElement("a");
      html2canvas(canvasID).then((canvas) => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", "证书.png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
        this.btnLoading = false;
      });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>

<style lang="scss" scoped>
.cred-img-container {
  display: flex;

  .buttons {
  }

  .img-wrapper {
    margin-left: 20px;
    position: relative;

    .base {
      width: 855px;
      height: 605px;
    }

    .photo0 {
      position: absolute;
      top: 248px;
      left: 128px;
      width: 144px;
      height: 180px;
    }

    .photo {
      position: absolute;
      top: 172px;
      left: 151px;
      width: 173px;
      height: 200px;
    }

    .photo8 {
      position: absolute;
      top: 207px;
      left: 230px;
      width: 110px;
      height: 135px;
    }

    .photo10 {
      position: absolute;
      top: 207px;
      left: 241.5px;
      width: 110px;
      height: 135px;
    }

    .photo11 {
      position: absolute;
      top: 207px;
      left: 230px;
      width: 110px;
      height: 135px;
    }

    .photo9 {
      position: absolute;
      top: 193px;
      left: 97px;
      width: 180px;
      height: 210px;
    }
    .photo6 {
      position: absolute;
      top: 180px;
      left: 200px;
      width: 115px;
      height: 160px;
      overflow: hidden;
    }

    .item01 {
      position: absolute;
      top: 245px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 75px;
      text-align: center;
    }

    .item02 {
      position: absolute;
      top: 295px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 75px;
      text-align: center;
    }

    .item03 {
      position: absolute;
      top: 403px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 95px;
      text-align: center;
    }

    .item04 {
      position: absolute;
      top: 403px;
      left: 640px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 95px;
      text-align: center;
    }

    .item05 {
      position: absolute;
      top: 295px;
      left: 610px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 100px;
      text-align: center;
    }

    .item06 {
      position: absolute;
      top: 355px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 180px;
      text-align: center;
    }

    .name {
      width: 90px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      bottom: 195px;
      left: 130px;
    }

    .time {
      width: 220px;
      position: absolute;
      bottom: 42px;
      right: 82px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time8 {
      width: 200px;
      position: absolute;
      bottom: 60px;
      right: 135px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time9 {
      width: 200px;
      position: absolute;
      bottom: 15px;
      right: 95px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }
    .info {
      width: 185px;
      position: absolute;
      top: 182px;
      right: 200px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;

      .item {
        margin-top: 8px;
        text-align: center;
      }

      .item1 {
        margin-top: 0px;
      }
      .item2 {
        margin-top: 15px;
      }

      .item3 {
        margin-top: 20px;
      }

      .item4 {
        margin-top: 20px;
      }

      .item5 {
        margin-top: 18px;
        width: 250px;
      }
      .item6 {
        margin-top: 16px;
        width: 150px;
        // border: 1px solid;
      }
      .item7 {
        width: 150px;
        // border: 1px solid;
      }

      .item8 {
        width: 150px;
        // border: 1px solid;
      }

      .item9 {
        width: 190px;
        margin-left: -14px;
      }

      .item10 {
        width: 250px;
        // border: 1px solid;
        margin-top: 9px;
        // margin-bottom: 10px;
      }

      .item11 {
        width: 90px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #d87830;
        position: absolute;
        top: 170px;
        left: -380px;
      }
      .item12 {
        margin-top: 16px;
        width: 150px;
        // border: 1px solid;
      }
      .item13 {
        width: 150px;
        margin-top: 22px;
        // border: 1px solid;
      }

      .item14 {
        width: 150px;
        margin-top: 24px;
        // border: 1px solid;
      }

      .item15 {
        width: 190px;
        margin-left: 32px;
        margin-top: 24px;
        // border: 1px solid;
      }

      .item16 {
        width: 250px;
        // border: 1px solid;
        margin-top: 24px;
        // margin-bottom: 10px;
      }

      .item17 {
        width: 90px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #d87830;
        position: absolute;
        top: 245px;
        left: -400px;
      }

      .item18 {
        margin-top: 34px;
        width: 155px;
        margin-left: 12px;
        // border: 1px solid;
      }

      .item23 {
        margin-top: 34px;
        width: 190px;
        margin-left: 12px;
        // border: 1px solid;
      }

      .item19 {
        width: 155px;
        margin-left: 12px;
        // border: 1px solid;
      }

      .item20 {
        width: 155px;
        margin-left: 12px;
        // border: 1px solid;
      }

      .item21 {
        width: 190px;
        margin-left: -5px;
        margin-top: 7px;
        // border: 1px solid;
      }

      .item22 {
        width: 90px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #d87830;
        position: absolute;
        top: 170px;
        left: -380px;
      }

      .item24 {
        width: 210px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #d87830;
        position: absolute;
        top: 150px;
        left: -447px;
        // border:1px solid;
      }

      .item25 {
        margin-top: 20px;
        width: 155px;
        margin-left: 2px;
        // border: 1px solid;
      }

      .item26 {
        width: 155px;
        margin-top:16px;
        margin-left: 2px;
        // border: 1px solid;
      }

      .item27 {
        width: 155px;
        margin-top:18px;
        margin-left: 0px;
        // border: 1px solid;
      }

      .item28 {
        width: 170px;
        margin-left: -5px;
        margin-top: 18px;
        // border: 1px solid;
      }
    }

    .name2 {
      font-size: 42px;
      font-weight: 600;
      // color: #D87830;
      position: absolute;
      top: 250px;
      left: 400px;
    }

    .info2 {
      position: absolute;
      bottom: 62px;
      left: 165px;
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 600;

      .item2 {
        margin-top: 3px;
      }

      .item3 {
        padding-left: 40px;
      }
    }

    .time2 {
      width: 120px;
      position: absolute;
      bottom: 55px;
      right: 115px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
    }

    .name6 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      // border: 1px solid red;
      width: 220px;
      text-align: center;
      color: rgb(12, 52, 93);
      position: absolute;
      top: 195px;
      left: 442px;
    }

    .sex6 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      width: 220px;
      text-align: center;
      color: rgb(12, 52, 93);
      position: absolute;
      top: 225px;
      left: 442px;
    }

    .code6 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      width: 220px;
      text-align: center;
      letter-spacing: 3px;
      color: rgb(12, 52, 93);
      position: absolute;
      top: 285px;
      left: 442px;
    }

    .deptdiv {
      width: 220px;
      height: 18px;
      // border:1px solid red;
      position: absolute;
      top: 255px;
      left: 442px;
    }

    .deptname6 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      color: rgb(12, 52, 93);
      text-align: center;
      top: 245px;
      left: 500px;
    }

    .deptname7 {
      font-size: 13px;
      font-weight: 600;
      // border: 1px solid;
      text-align: center;
      font-family: "宋体";
      color: rgb(12, 52, 93);
      top: 258px;
      left: 445px;
    }

    .validtime {
      font-size: 18px;
      font-weight: 600;
      color: rgb(12, 52, 93);
    }

    .starttime {
      font-family: "宋体";
      position: absolute;
      top: 313px;
      left: 465px;
    }

    .wave {
      font-family: "Times New Roman";
      font-weight: normal;
      color: rgb(12, 52, 93);
      position: absolute;
      top: 313px;
      left: 560px;
    }

    .endtime {
      font-family: "宋体";
      position: absolute;
      top: 313px;
      left: 570px;
    }

    .time6 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      color: rgb(12, 52, 93);
      position: absolute;
      top: 505px;
      left: 562px;
    }

    .span2 {
      position: absolute;
      top: 0px;
      left: 63px;
    }

    .span3 {
      position: absolute;
      top: 0px;
      left: 105px;
    }

    .name7 {
      font-size: 18px;
      font-weight: 600;
      font-family: "宋体";
      color: rgb(12, 52, 93);
      position: absolute;
      top: 360px;
      left: 112px;
    }
  }

  .img-wrapper2 {
    margin-left: 20px;
    position: relative;

    .base {
      width: 445px;
      height: 618px;
    }

    .photo {
      width: 78px;
      height: 106px;
      position: absolute;
      top: 225px;
      left: 183px;
    }

    .photo4 {
      width: 80px;
      height: 106px;
      position: absolute;
      top: 269px;
      left: 185px;
    }

    .photo6 {
      width: 58px;
      height: 75px;
      position: absolute;
      top: 142px;
      left: 192px;
    }

    .name {
      width: 65px;
      position: absolute;
      bottom: 255px;
      left: 43px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .qishu {
      width: 23px;
      position: absolute;
      bottom: 211px;
      left: 63px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .time3 {
      width: 155px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 108px;
      right: 62px;
      font-size: 14px;
      font-weight: 600;
    }

    .party4 {
      width: 100px;
      position: absolute;
      bottom: 202px;
      left: 190px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .name4 {
      width: 110px;
      position: absolute;
      bottom: 180px;
      left: 180px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .name6 {
      width: 65px;
      position: absolute;
      bottom: 305px;
      left: 46px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .code4 {
      width: 110px;
      position: absolute;
      bottom: 133px;
      left: 200px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .code6 {
      position: absolute;
      bottom: 28px;
      left: 210px;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
    }

    .time {
      width: 73px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 230px;
      left: 112px;
      font-size: 14px;
      font-weight: 600;
    }

    .time6 {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 76px;
      right: 66px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .img-wrapper3 {
    margin-left: 20px;
    position: relative;

    .base {
      max-width: 850px;
    }
  }
}
</style>
